@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Dosis';
  src: url('./assets/fonts/font-dosis/Dosis-VariableFont_wght.ttf') format('woff2');
}


@font-face {
  font-family: 'Sacramento';
  src: url('./assets/fonts/Sacramento/Sacramento-Regular.ttf') format('woff2');
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Dosis';
  overflow-x: hidden;
}



.logo {
  font-family: 'Sacramento';
}

h1 {
  font-family: 'Sacramento';
  font-size: 3.25em !important;
}

.intro {
  background-image: url('assets/img/background2.jpg');
  background-repeat: no-repeat;
}

@media (max-width: 1000px) {
  .intro {
    background-image: url('assets/img/background2-md.jpg');
  }
}

@media (max-width: 600px) {
  .intro {
    background-image: url('assets/img/background2-sm.jpg');
  }
}


